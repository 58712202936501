<template>
  <div>
    <div style="margin-top: 50px;padding:0 20px 20px;overflow-y: auto;height:625px"  v-html="product.detailRequirement">
   
    </div>
    <!-- <div class="pictureDescription" >
          <img src="https://qncdn.tripodeck.com/10001/upload/img/20230504085750076" alt="">
          <div class="pictureDescription_text">
            <p>・Harry Potter and the Forbidden Journey™</p>
            <p>・Harry Potter and the Forbidden Journey™</p>
            <p>・Harry Potter and the Forbidden Journey™</p>
            <p>・Harry Potter and the Forbidden Journey™</p>
          </div>
        </div>  -->
    <div class="foot">
      <div>
        <button class="cancel_btn" @click="closeDialog">CANCEL</button>
        <el-button @click="selectDate" :disabled="product.votes == 0" :type="product.votes > 0? 'primary':'info'">SELECT A DATE</el-button>
      </div>
    </div>
    <close-icon @closeDialog="closeDialog" />
  </div>
</template>

<script>
import closeIcon from '@/components/close_icon.vue'
export default {
  components: { closeIcon },
  data() {
    return {

      product: {},
    }
  },
  mounted () {
      this.product = JSON.parse(decodeURI(this.$route.query.item));
    },
  methods: {
    
    selectDate() {
      this.$router.push({ path: "/optionsDateDialog", query: { item: encodeURI(JSON.stringify(this.product)) } });
      this.$emit('selectDate', this.product)
      this.$emit('footShow')
    },
    closeDialog() {
      this.$router.push({ path: "/options" });// 关闭弹框重置数据
      // this.$emit('footShow')
      // this.$emit('closeDialog')
    },
  }
}
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  border-radius: 12px;
  width: 747px;
}
@media (max-width: 768px) {
  /deep/.el-dialog {
    margin-top: 0 !important;
    margin-bottom: 0;
    height: 100vh !important;
    border-radius: 0;
    width: 100% !important;
    .dialog_body {
      height: calc(100vh - 143px);
      margin-top: 40px;
      .dialog-body {
        height: inherit;
      }
    }
  }
  .el-dialog__header {
    display: none;
    padding: 0px !important;
  }
}
.el-dialog__header {
  display: none;
  padding: 0px !important;
}
::v-deep .el-dialog__body {
  overflow-y: auto;
  padding: 0;
  margin-top: -27px;
}
.dialog_body {
  height: 565px;
  overflow-y: auto;
  padding: 0 20px 20px;
  margin-top: 20px;
}
.notes_title {
  display: box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  align-items: center;
  margin-top: 10px;
  img {
    margin-right: 10px;
    margin-top: 3px;
    align-self: baseline;
  }
  span {
    font-weight: bold;
    font-size: 15px;
  }
}
.note_list {
  .note_item {
    display: box;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-align-items: center;
    -webkit-align-items: center;
    align-items: center;
  }
}
.content {
  padding-left: 15px;
  position: relative;
  &::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: 8px;
    left: 5px;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: #d5ab81;
  }
  a {
    text-decoration: underline;
    color: #003399;
  }
}
.dialog_title {
  background-color: #003399;
  color: white;
  font-weight: bold;
  padding: 15px 10px;
  // margin-top: 10px;
  &:not(:first-child) {
    margin-top: 18px;
  }
  img {
    margin-right: 15px;
  }
}
.dialog_title {
  @media (max-width: 768px) {
    margin-top: 10px;
    width: 100%;
    height: 100%;
  }
}
.map_box {
  border: 1px solid #9fbedf;
  padding: 15px 10px;
  margin-top: 15px;
  .map_img_box {
    display: flex;
    @media (max-width: 768px) {
      flex-direction: column;
    }
    img {
      width: 300px;
      height: 200px;
      @media (max-width: 768px) {
        margin-top: 15px;
        width: 100%;
        height: 100%;
      }
    }
  }
}
.foot {
  position: fixed;
  bottom: 0;
  background-color: #eee;
  padding: 16px 32px;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin: 0;
  font-size: 14px;
  border-top: 1px solid rgb(167, 167, 167);
  border-radius: 0 0 12px 12px;
  .back_btn {
    font-weight: 700;
    font-style: normal;
    font-size: 14px;
    color: #036ce2;
    background-color: inherit;
    border: none;
    padding-left: 0;
    ::v-deep .el-icon-arrow-left::before {
      font-weight: 700;
    }
  }
  .cancel_btn {
    font-weight: 700;
    height: 40px;
    line-height: 40px;
    width: 102px;
    text-align: center;
    margin-right: 16px;
    color: #036ce2;
    border: 1px solid #036ce2;
    cursor: pointer;
    border-radius: 12px;
    background-color: white;
    box-sizing: border-box;
  }
  .select_btn {
    height: 44px;
    line-height: 44px;
    background-color: rgba(3, 108, 226, 1);
    border: 1px solid rgb(3, 108, 226);
    border-radius: 12px;
    color: white;
    font-weight: 700;
    width: 148px;
  }
  .el-button {
    border-radius: 12px;
  }
  .el-button--info {
    background-color: #c8c9cc;
    border-color: #c8c9cc;
  }
}
@media (max-width: 768px) {
  .foot {
    padding: 16px;
    div {
      display: flex;
      flex-direction: column-reverse;
      width: 100%;
      .select_btn {
        width: 100%;
      }
      .cancel_btn {
        width: 100%;
        background-color: inherit;
        border: none;
      }
    }
  }
}
::v-deep .el-dialog__headerbtn {
  display: none;
  padding: 0px !important;
}
.pictureDescription{
  display: flex;
  flex-wrap: nowrap;
  // justify-content: center;
  // align-items: center;

}
.pictureDescription_text{
    margin-left: 15px;
    p:not(:first-child){
      margin:10px 0
    }
  }
</style>